@import 'common-flagship_tasting_room';
@import 'common-non_commerce';

//stylelint-disable
.pswp__img {
	background: $color-product_image-background;
}

.b-dialog-btn.m-alt {
	border: none;

	@media (hover: hover) and (pointer: fine) {
		&:hover {
			background: var(--button_alt-background_color, $color-button_alt-background);
			color: var(--button_alt-text_color, $color-button_alt-text);
		}
	}
}

.b-dialog-btn.m-alt .b-dialog-btn_text {
	@include t-link_underlined($state: default, $underline-offset: 5px, $font-weight: inherit);
	@include t-link_underlined($state: hover);

	letter-spacing: inherit;
}

.b-header.m-transparent:not(.m-inverted):not(.m-active):not(.m-stuck):not(.m-hamburger_opened) {
	color: inherit;
}

.b-header-background {
	border-bottom: 1px solid $color-secondary;
}
.b-categories_navigation { 
	 &-item_1:after {
		border-bottom: 3px solid $color-secondary;
 	}
	&-group_1 {
		border-top: 1px solid $color-secondary;
		.t-link_1 , .t-heading_6 {
			color: $color-secondary-text;
		}
	}
}

.b-categories_navigation-list_1 {
	color: $color-process-Black;
	font-family: $font-alt;
	font-size: 24px;
	letter-spacing: 0.3px;
	line-height: 40px;
	font-weight: 200 !important;
}

.b-categories_navigation-link_2 , .b-categories_navigation-link_3.m-view_all {
	color: $color-secondary;
}

.b-product {
	&_tile-name {
		color: $color-secondary-text;
	}
	&_price-value {
		font-weight: 300;
	}
}

svg {
	stroke: $color-secondary;
}

.b-product_gallery-thumb.m-current:after{
    border-color: $color-secondary !important;
}
.b-product_tile-cta {
	display: none;
}

.b-breadcrumbs-link.m-current {
	color: var(--color-link, $color-primary) !important;
}

.b-breadcrumbs {
	&-link {
		color: var(--color-link, $color-secondary-text) !important;
		@include media(sm) {
			color: $color-shade_7 !important;
		}
	}
    &-item {
        align-items: baseline;
        &:not(.m-current) {
            @include media(md-up) {
                @include icon('arrow-right', 8px, 8px, after) {
                    background: $color-breadcrumb-separator_bg;
                    margin: 2px 15px 0;
                }
            }
        }
    }
}

.b-footer-contacts .content-asset {
	@include media(sm) {
		padding-bottom: 0;
	}
}
.b-footer {
	&-customer_service , &-need_help{
		visibility: hidden;
	}
	&-bottom_info, &_legal-links {
		@include media(sm) {
			align-items: center;
		}
	}
	&-copyright {
		color: $color-primary !important;
		opacity: initial;
		@include media(sm) {
			text-align: center !important;
		}
	}
	&-social {
		@include media(md) {
			grid-area: auto !important;
		}
	}
	&_social {
		@include media(md-up) {
			padding-top: 64px;
		}
		text-align: left;
		&-title {
			color: $color-primary !important;
			text-transform: capitalize;
			@include media(sm) {
				text-align: center;
			}
		}
		&-links {
			justify-content: flex-start;
		   & a {
				padding-right: 16px;
			}
			@include media(sm) {
				justify-content: center;
			}
		}
	}
	@include media(md-down) {
		background: transparent;
	}
	background: transparent;
	border-top: 1px solid $color-shade_5;
	&_navigation {
		&-content {
			@include media(md-down) {
				align-items: center;
				letter-spacing: 0.4px;
			}
		}
		&-title {
			text-transform: uppercase;
			letter-spacing: 0.4px;
			color: $color-primary !important;
			@include media(md-down) {
				text-align: center;
			}
		}
	}
	
}

.logo {
	&-desktop {
		margin-top: 5%;
		@include media(sm){
			display: none;
	
		}
		img {
			width: 100%;
		}
	}
	&-mobile {
		display: none;
		margin-top: 5%;
		@include media(sm){
			display: block;
		}
		img {
			width: 100%;
		}
	}
}

.b-header.m-stuck .logo-desktop {
	@include media(sm){
		display: none;
	}
	
	display: block;
}
.b-header.m-stuck .logo-mobile {
	@include media(sm){
		display: block;
	}
}

.f-input_checkbox input , .f-input_radio-field , .f-input_radio-field.m-checked {
	background: $color-secondary-text;
}
.b-refinements_accordion-button:after {
    background: var(--icon-color,$color-secondary-text) !important;
}
.b-refinement_list-more {
	color: $color-secondary-text;
}
.b-refinements_accordion-button {
	font-size: 16px !important;
}

.l-plp-title {
	font-size: 36px !important;
	line-height: 48px !important;
}
.l-products_results-products_number {
	font-size: 16px !important;
    line-height: 24px !important;
	text-transform: capitalize !important;
	letter-spacing: 0.4px;
}

.l-products_results {
	&-sorting {
		@include media(md-down){
			grid-template-columns: 1fr 1fr;
		}
	}
	
}

.l-products_results-refinement_button {
	background: $color-secondary !important;
}

.b-product_badge {

	&.m-promo {
		background: $color-secondary;
	}
	color: $color-primary !important;
	&-image {
		background: none !important;
		border: none;
	}
	&.m-rating_points {
		background-image: url("./svg-icons/badge-shape.svg");
		background-repeat: no-repeat;
	}
	&-abbr, &-text {
		font-size: 16px;
		letter-spacing: 0.15px;
		line-height: 24px;

		@include media(md-down) {
			font-size: 12px;
			letter-spacing: 0.12px;
			line-height: 14px;
		}
	}
	&-points {
		color: $color-shade_7;
        font-family: $font-main;
        font-size: 20px !important;
        letter-spacing: 0.5px;
        line-height: 32px;
		margin-right: 4px;
		@include media(md-down) {
            line-height: 15px;
			font-size: 14px;

        }
	}
	&-text {
        font-size: 12px !important;
        letter-spacing: 0.2px;
        line-height: 18px;
        color:$color-shade_5;
		margin-right: 4px;
		@include media(md-down) {
            line-height: 15px;
			font-size: 14px;
        }
    }
}

.b-carousel-control {
    border: 0;
}

.b-variations_item-label {
    color: $color-shade_3 !important;
}

// subheading in homepage
.subhead_ {
	font-family: $font-main;
	font-size: 28px;
	letter-spacing: 0.5px;
	line-height: 40px;
	color: $color-shade_7;
	font-weight: 200;
}

.b-carousel-title {
  font-family: $font-alt;
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0.3px;
  line-height: 48px;
  margin-left:25px;
  color: $color-process-Black;
}

.mainhead {
	font-family: $font-alt;
	font-size: 48px;
	letter-spacing: 0.5px;
	line-height: 56px;
	text-align: center;
	font-weight: 100;
	margin-bottom: 56px;
	@include media(sm) { 
		margin-bottom: 0;
	}

}

.b-header-links_item:before, .b-header-utility_item:before {
	color: $color-secondary;
}

.b-store_locator-title {
	font-family: $font-alt;
	font-size: 36px;
	letter-spacing: 0.3px;
	line-height: 48px;
}

.b-header_promo-content {
	padding: 12px 0;
	background: $color-shade_8;
	color: $color-shade_7;
	font-family: $font-main;
	font-size: 16px;
	letter-spacing: 0.4px;
	line-height: 24px;
	& a {
		color: $color-secondary;
	}
}

.b-carousel {
    &-control{
        border: 0;
        --icon-color: var(--carousel-control-color, #{$color-carousel_control});
        &[disabled] {
            --icon-color: var(--carousel-control-color, #{$color-carousel_control__disabled});
            opacity: initial;
            }
    }
    &-pagination_control{
        border: 1px solid $color-secondary;
    }
}

//Age Gate
.b-age_gate {
	background: $color-primary-background;
	&-inner {
		color: $color-primary;
	}
}

// Banner Button width
.g-button_main.m-width_m {
	width: auto;
	padding: 0 20px;
}

.b-back_to_top {
	&-icon {
		margin-bottom: 0 !important;
		color: $color-secondary;
	}
	&-copy {
		font-size: 16px;
		color: $color-primary;
		text-transform: capitalize;
		font-weight: 300;
	}
}

.t-paragraph_1 {
	font-size: 16px;
}

.b-header-links_item .m-stores , .b-header-utility_item .m-search{
	color: $color-shade_7 !important;
}

.b-header-links_item.m-stores, .b-header-utility_item.m-search, .b-header_promo-content {
	font-size: 15px;
}

.f-input_select-field {
	color: $color-process-Black !important;
	border: solid $color-process-Black !important;
	font-size: 14px;
	font-weight: 500;
}

button.b-search_input-clear.m-visible {
	color: $color-secondary-text;
	text-decoration: underline;
}

.b-header-hamburger_cta {
	color: $color-secondary;
}

.b-header-utility_item.m-search {
	@include media(md-down) {
		font-size: 0;
	}
}

#submitBtnWrapper .b-form-btn_alt {
	background: $color-secondary;
	color: $color-primary-background;
	&:hover {
		background: $color-primary-background;
		color: $color-secondary;
	}
}

.f-input_select-field {
	border-width: 1px !important;
	font-size: 14px;
	font-weight: 500;
}
.f-input_select:before {
	color: $color-secondary-text !important;
}

.f-input_text-label {
	font-weight: 500 !important;
	font-size: 14px !important;
}

.f-input_text-field {
	color: $color-process-Black !important;
	&:focus + label {
		color: $color-primary !important;
	}
}

#moreInfoLabel {
	font-weight: 500;
    font-size: 14px;
    line-height: 16px;
}

.b-search_input-submit, .b-button_close.m-small {
	color: $color-secondary !important;
}

.b-product_badge-points, .b-product_badge-text{
	color: $color-process-Black !important;
}

.b-text_box h1 {
	@include media(sm) {
		font-size: 28px !important;
	}
}

.f-input_select-field > option, .b-filter_selected-value {
    color: $color-process-Black !important;
}

.b-plp_sorting-container .f-input_select-field {
    border-width: 0 0 1px !important;
}

.b-footer-copyright, .b-footer_legal-link {
	font-size: 12px !important;
}

#purchaseDate + .f-input_text-label {
	@include media(sm) {
		font-size: 10px !important;
	}
}

.b-product_badge-label, .b-product_badge.m-promo {
  color: $color-primary-background;
}

.b-product_badge.m-rating_points {
	background-size: contain;
}

@media screen and (max-width: 1199px) {
	.b-product_badge-points, .b-product_badge-text {
	   margin: 0 !important;
	}
	.b-plp_sorting-label {
		color: $color-process-Black !important;
		text-transform: capitalize !important;
	}
}

.b-button_close.m-small, .b-search_input-submit, .b-plp_sorting-label:after,
.b-breadcrumbs-link:before, .b-header-navigation_link.m-stores .b-header-navigation_link_inner:before,
.b-header-close_hamburger:before, .b-customer_service_info-icon,
.b-slide_panel-close:before, .b-filter_selected-clear, .b-filter_selected-remove:before,
.l-page #wtb-overlay #closeBtn:after {
	color: $color-secondary-text !important;
}

.f-input_checkbox-field {
	background-color: $color-secondary-text !important;
}

.b-back_to_top-copy {
	margin-top: 10px;
}

.b-categories_navigation-link_1, .b-refinements_accordion-button {
	text-transform: uppercase;
}

.b-refinements-title {
	font-size: 16px;
    line-height: 24px;
}

.b-header-navigation_link.m-stores{
	color: $color-process-Black;
	text-transform: uppercase;
}